@font-face {
	font-family: "ivsymbols";
	src: url("../dist/a76df5419b64630a940e-ivsymbols.eot?#iefix") format("embedded-opentype"),
url("../dist/a76df5419b64630a940e-ivsymbols.svg#ivsymbols") format("svg"),
url("../dist/a76df5419b64630a940e-ivsymbols.ttf") format("truetype"),
url("../dist/a76df5419b64630a940e-ivsymbols.woff") format("woff"),
url("../dist/a76df5419b64630a940e-ivsymbols.woff2") format("woff2");
}

.icon {
	line-height: inherit;
}

.icon:before {
	font-family: ivsymbols !important;
	font-style: normal;
	font-weight: normal !important;
	vertical-align: top;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; 
}

.icon-arrow-right:before {
	content: "\f101";
}
.icon-close:before {
	content: "\f102";
}
.icon-cloud-down:before {
	content: "\f103";
}
.icon-envelope:before {
	content: "\f104";
}
.icon-facebook:before {
	content: "\f105";
}
.icon-hamburger:before {
	content: "\f106";
}
.icon-language:before {
	content: "\f107";
}
.icon-linkedin:before {
	content: "\f108";
}
.icon-organize:before {
	content: "\f109";
}
.icon-phone:before {
	content: "\f10a";
}
.icon-planet-grid:before {
	content: "\f10b";
}
.icon-play:before {
	content: "\f10c";
}
.icon-search:before {
	content: "\f10d";
}
.icon-share:before {
	content: "\f10e";
}
.icon-twitter:before {
	content: "\f10f";
}
.icon-youtube:before {
	content: "\f110";
}
